<template>
    <el-main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="推荐分群" name="second"></el-tab-pane>
            <el-tab-pane label="自定义分群" name="first"></el-tab-pane>
        </el-tabs>
        <div v-show="activeName == 'first'">
            <router-link to="/user/addGroup">
                <el-button type="primary" size="small">新建分群</el-button>
            </router-link>
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="title" label="人群名称"></el-table-column>
                <el-table-column prop="dy" label="人群定义"></el-table-column>
                <el-table-column prop="num" label="人群数量"></el-table-column>
                <el-table-column prop="visit" label="昨日访问量"></el-table-column>
                <el-table-column prop="operator_name" label="添加人"></el-table-column>
                <el-table-column label="操作">
                    <template v-slot="{ row }">
                        <el-button @click="checkUser(row.id, 2)" type="text">查看客户</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <!-- <el-button type="text">去运营</el-button>
            <el-divider direction="vertical"></el-divider> -->
                        <el-button @click="edit(row.id)" type="text">编辑</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button @click="remove(row.id)" type="text">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
        </div>
        <div v-show="activeName == 'second'">
            <div class="recommend-group">
                <div class="title">
                    <span></span>
                    推荐运营人数top3
                </div>
                <div class="recommend-list">
                    <div class="item">
                        <div class="top">
                            <div class="name">高价值流失人群</div>
                            <p class="content">近30天没有访问店铺;近360天有购买;近90天 无购买;有效购买次数大于3;笔单价大于100;</p>
                        </div>
                        <div class="center">
                            <div class="num">
                                <span>人群总数</span>
                                <span>{{ top.gj.num }}</span>
                            </div>
                            <div class="num">
                                <span>昨日访问量</span>
                                <span>{{ top.gj.visit }}</span>
                            </div>
                        </div>
                        <!-- <div class="bottom">创建运营计划</div> -->
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="name">需重点关注客户</div>
                            <p class="content">有效购买次数大于3；笔单价大于100;</p>
                        </div>
                        <div class="center">
                            <div class="num">
                                <span>人群总数</span>
                                <span>{{ top.focus.num }}</span>
                            </div>
                            <div class="num">
                                <span>昨日访问量</span>
                                <span>{{ top.focus.visit }}</span>
                            </div>
                        </div>
                        <!-- <div class="bottom">创建运营计划</div> -->
                    </div>
                    <div class="item">
                        <div class="top">
                            <div class="name">新成交客户</div>
                            <p class="content">近90天有购买；有效购买次数大于0;</p>
                        </div>
                        <div class="center">
                            <div class="num">
                                <span>人群总数</span>
                                <span>{{ top.new.num }}</span>
                            </div>
                            <div class="num">
                                <span>昨日访问量</span>
                                <span>{{ top.new.visit }}</span>
                            </div>
                        </div>
                        <!-- <div class="bottom">创建运营计划</div> -->
                    </div>
                </div>
            </div>
            <el-table :data="recommand" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="title" label="人群名称"></el-table-column>
                <el-table-column prop="define" label="人群定义"></el-table-column>
                <el-table-column prop="value" label="人群数量"></el-table-column>
                <el-table-column prop="visit" label="昨日访问量"></el-table-column>
                <el-table-column label="操作">
                    <template v-slot="{ row }">
                        <el-button @click="checkUser(row.type, 1)" type="text">查看客户</el-button>
                        <!-- <el-divider direction="vertical"></el-divider>
            <el-button type="text">去运营</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            page: 1,
            rows: 10,
            list: [],
            total: 0,
            top: {
                focus: {},
                gj: {},
                new: {},
            },
            recommand: [],
            activeName: 'second',
        };
    },
    created () {
        this.getList();
        this.getRecommend();
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.rows = val
                this.getList()
            } else {
                this.page = val
                this.getList()
            }
        },
        getList () {
            this.$axios
                .post(this.$api.member.groupList, {
                    page: this.page,
                    rows: this.rows,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list;
                        list.map(item => {
                            item.num = item?.num ?? 0;
                        });
                        this.list = list;
                        this.total = res.result.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        checkUser (cluster_id, type) {
            this.$router.push({
                path: '/user/userList',
                query: {
                    cluster_id: cluster_id,
                    type: type,
                },
            });
        },
        getRecommend () {
            this.$axios.post(this.$api.member.getRecommend).then(res => {
                if (res.code == 0) {
                    this.top = res.result.top;
                    this.recommand = res.result.recommand;
                }
            });
        },
        remove (id) {
            this.$confirm('确认删除此分群？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.post(this.$api.member.groupDel, { id: id }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.$message.success('删除成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        edit (id) {
            this.$router.push({
                path: '/user/addGroup',
                query: {
                    id: id,
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    font-size: 14px;

    .recommend-group {
        .title {
            background: #f8f9fa;
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0 10px;

            span {
                width: 3px;
                height: 14px;
                background: #1467ff;
                margin-right: 10px;
            }
        }

        .recommend-list {
            margin: 16px 0 30px 0;
            display: flex;
            margin-right: 20px;

            .item {
                width: 310px;
                // height: 236px;
                box-shadow: 0px 4px 18px 0px rgba(236, 236, 236, 0.6);
                border-radius: 4px;
                margin-right: 20px;

                .top {
                    height: 106px;
                    border-radius: 4px 4px 0px 0px;
                    color: #fff;
                    padding: 15px;

                    .name {
                        font-size: 18px;
                        margin-bottom: 8px;
                    }

                    .content {
                        line-height: 22px;
                    }
                }

                .center {
                    height: 48px;
                    margin: 15px 0;
                    display: flex;

                    .num {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        span:nth-child(1) {
                            color: #999;
                            margin-bottom: 5px;
                        }

                        span:nth-child(2) {
                            font-weight: bold;
                            font-size: 22px;
                        }

                        &:nth-child(1) {
                            border-right: 1px solid #ebedf0;
                        }
                    }
                }

                .bottom {
                    line-height: 36px;
                    text-align: center;
                    width: 278px;
                    margin: 0 auto;
                    cursor: pointer;
                }

                &:nth-child(1) .top {
                    background: #298aeb;
                }

                &:nth-child(2) .top {
                    background: #ff873f;
                }

                &:nth-child(3) .top {
                    background: #a32bff;
                }

                &:nth-child(1) .bottom {
                    border: 1px solid #298aeb;
                    color: #298aeb;
                }

                &:nth-child(2) .bottom {
                    border: 1px solid #ff873f;
                    color: #ff873f;
                }

                &:nth-child(3) .bottom {
                    border: 1px solid #a32bff;
                    color: #a32bff;
                }
            }
        }
    }

    .el-table {
        margin-top: 10px;
    }
}</style>
